import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { AppSettings, HelperService, ProjectService, AuthenticationService, ChildProjectsService } from './services/_services';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  public routerSubscription : any;
  public loading            : any = false;

  constructor(
    public router                 : Router,
    public activatedRoute         : ActivatedRoute,
    public swPush                 : SwPush,
    public swUpdate               : SwUpdate,
    private helperService         : HelperService,
    private projectService        : ProjectService,
    private childProjectsService  : ChildProjectsService,
    private authenticationService : AuthenticationService,
  ){
    this.routerSubscription = this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {

        if(val.url.includes('login') || val.url.includes('orphan-profile-builder') || val.url.includes('view-orphan-profile') || val.url.includes('orphans-database') || val.url.includes('') || val.url.includes('unpaid')  ){
          if(typeof $ != "undefined"){
              $("html").addClass("noZoom__");
              }
          }
          else{
              if(typeof $ != "undefined"){
                  $("html").removeClass("noZoom__");

              }
          }

        // Get Project Details if user is not logged in
        if(!this.authenticationService.get_currentUserValue()) {
          this.childProjectsService.removeChildProjects();
          if(val.url.split('/')[2]) {
              let project_url_key = val.url.split('/')[2];
              if(!this.projectService.matchProject_url_key(project_url_key)) {
                  this.projectService.removeProject();
                  this.getProjectDetails(project_url_key);
              }
          } else {
              this.projectService.removeProject();
          }
        }
        else{
          this.childProjectsService.getChildProjects();
        }
      }
    });
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(event => {
        console.log('current version is', event.current);
        console.log('available version is', event.available);
      });
      this.swUpdate.activated.subscribe(event => {
        console.log('old version was', event.previous);
        console.log('new version is', event.current);
      });

      this.swUpdate.available.subscribe(event => {
        this.swUpdate.activateUpdate().then(() => this.updateApp());
      });
    }
  }

  ngOnDestroy(): void {
    if(this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  updateApp() {
    document.location.reload();
    console.log("The app is updating right now");
  }

  getProjectDetails(projectUrlKey) {
    // Show Loader
    this.loading = true;

    // let data = new FormData(this.admin);
    let url  = AppSettings.API_ENDPOINT + 'adminPortal_users.php';
    let data = new FormData();
    data.append("action",     "getProjectDetails");
    data.append("url_key",     projectUrlKey);

    this.helperService.httpPostRequests(url, data)
     .then(resp => {
          if(resp[0]) {
              this.projectService.setProject(resp[0]);
          } else {
              // Show Error (Project Not Found)
              Swal.fire('', 'Project Not Found', 'error');
          }

          // Hide Loader
          this.loading = false;
      },
      error => {
          console.log("error: ",error);

          // Hide Loader
          this.loading = false;
      });
  }
}
