import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertIdService {
  private alertID  = '';
  constructor() {
  }

  setAlertId(id){
    this.alertID = id;
  }

  getAlertId(){
    return this.alertID;
  }
}
