import { Injectable, Inject  } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChildProjectsService {

  public childProjects : any;

  private childProjectsS: BehaviorSubject<any> = new BehaviorSubject<any>(this.childProjects);
  childProjectsS$: Observable<any> = this.childProjectsS.asObservable();

  constructor() {
    if(typeof localStorage != "undefined") {
      if(localStorage.getItem('childProjects')) {
        this.childProjects  = JSON.parse(localStorage.getItem('childProjects'));
        this.childProjectsS.next(this.childProjects);
      }
      else
        this.childProjects = [];
    }
  }

  setChildProjects(childProjects) {
    try {
      localStorage.setItem('childProjects', JSON.stringify(childProjects));
      this.childProjects = childProjects;
      this.childProjectsS.next(this.childProjects);
    }
    catch (e) {

    }
  }

  removeChildProjects() {
    try {
      localStorage.removeItem('childProjects');
      this.childProjects = null;
      this.childProjectsS.next(this.childProjects);
    }
    catch (e) {

    }
  }

  // matchProject_url_key(url_key) {
  //   if(url_key == this.childProjects.url_key)
  //     return this.childProjects;
  //   else
  //     return false;
  // }

  getChildProjects() {
    return this.childProjects;
  }

}
