import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public corsHeaders: any = new HttpHeaders({
  });;
  public keySub: any;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  private value;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {
    if(typeof localStorage != "undefined") {
      // // Set Params OnLoad
      // this.searchParms = JSON.parse(localStorage.getItem('searchParms'));

      this.keySub = this.authenticationService.UserLoginKeyS$.subscribe(loginKey => {
        this.corsHeaders = {};
        this.corsHeaders = {
                    headers: new HttpHeaders()
                      .set('Cache-Control',  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0')
                      .set('Pragma',   'no-cache')
                      .set('Expires',  '0')
                      .set('Authorization',  `Bearer ${loginKey}`)
                      // .set('AuthKey',  ``+ loginKey)
                  }
      });
    }
  }

  fetchData(url) : Promise<any> {
    return this.http.get(url, this.corsHeaders)
            .pipe( takeUntil(this.ngUnsubscribe) )
            .toPromise()
            .then( resp => {
                this.value = resp;
                return this.value;
            })
            .catch(error => {
                console.log("helperFunc error: ",error);

                // Show Error Msg
                if(typeof error.error != "undefined") {
                    if(error.error.status == "403" || error.error.status == "401") {
                      this.authenticationService.simpleLogout();
                    }
                    throw error;
                } else {
                    throw "Something went wrong. Please try again.";
                }
            });
  }

  updateData(){
    this.value = this.value + 10;
  }

  getData(){
    return this.value;
  }

}
