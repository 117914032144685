export class AppSettings {
   public static API_ENDPOINT    = 'https://adminbackend.pakistansweethome.org.pk/api/';
   public static ACCOUNT_ENDPOINT    = 'https://adminbackend.pakistansweethome.org.pk/account/';
   public static PDF_ENDPOINT    = 'https://paymentplans.gharbaar.com/';
   public static API_ENDPOINT_GB_AREA   = 'https://api-live.gharbaar.com/api/';
   public static GOOGLE_MAPS_KEY     = 'AIzaSyDkwqsIbqWQR3ZH3n9WEfJ8YDdgCFDLZsg';
   public static API_ENDPOINT_AREA   = 'http://192.168.18.67:8000/api/';
   public static PORTALS_ENDPOINT    = 'https://portal-backend.gharbaar.com/';
   public static leadSources        =   ['UAN', 'Zameen', 'Facebook', 'Facebook-Web', 'Google-Web', 'Youtube-Web', 'Website'];
   public static subscription        =  ['Active','Paused'];
   public static applicationActions        =  [
                                                    {
                                                      id:"1",
                                                      name:"Assign Test Center"
                                                    },
                                                  {
                                                    id:"2",
                                                      name:"Issue Roll No Slip"
                                                  },
                                                {
                                                  id:"3",
                                                  name:"Delete"
                                                }
];

  public static applicationStatusFilter = [
                                      {
                                         id:'0',
                                        name:'Unchecked'
                                      },
                                      {
                                      id:'1',
                                       name:'Accepted'
                                     },
                                     {
                                      id:'2',
                                      name:'Rejected'
                                   },
                                     {
                                      id:'3',
                                      name:'Test Center Assigned'
                                   },
                                     {
                                      id:'4',
                                      name:'Roll No Slip Issued'
                                   }
                                  ];
public static testCenterFilter = [
'Abbotabad','Bahawalpur','Deraghazi Khan','Faisalabad','Gujranwala','Gilgit','Hyderabad','Jhelum','Kalat','Karachi','Kohat','Lahore','Mardan','Muzaffarabad','Multan','Mirpur','Okara','Pano Aqil	','Peshawar','Quetta','Rawlakot	','Rawalpindi','Skardu','Sargodha','Sialkot','Sukkar'
 ]
 public static testCenterAdress = {
"Abbotabad":"",
'Bahawalpur': '',
'Deraghazi Khan': '',
'Faisalabad': '',
'Gujranwala': '',
'Gilgit': '',
'Hyderabad': '',
'Jhelum': '',
'Kalat': '',
'Karachi': '',
'Kohat': '',
'Lahore': '',
'Mardan': '',
'Muzaffarabad': '',
'Multan': '',
'Mirpur': '',
'Okara': '',
'Pano Aqil': '',
'Peshawar': '',
'Quetta': '',
'Rawlakot	': '',
'Rawalpindi': '',
'Skardu': '',
'Sargodha': '',
'Sialkot': '',
'Sukkar': ''

 }
                                         ;
   public static genderFilter        =  ['Male','Female'];
  public static ageFilter        =       [4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
  public static gradesFilter        =    ['A+','A','A-','B+','B','B-'];
   public static applyingFor        =    [{
                                            id: 'preCadet',
                                            name : '8th Class'
                                          },
                                          {
                                            id: 'nursaryToSeven',
                                            name : 'Nursery - 7th Class'
                                          },
                                          {
                                            id: 'elevenClass',
                                            name : '11th Class'
                                          },
                                        ];
   public static filterType        =    ['One Time','Monthly', 'Sponser'];
   public static filterdonations    =  [
                                        {
                                          'name'  : '1',
                                          'id' : '1',
                                        },
                                        {
                                          'name'  : '2-5',
                                          'id' : 'twotofive',
                                        },
                                        {
                                          'name'  : '>5',
                                          'id' : '>5',
                                        }
                                      ];
   public static amountRangeFilter    =  [
                                        {
                                          'name'  : '0-50',
                                          'id' : '50',
                                        },
                                        {
                                          'name'  : '50-100',
                                          'id' : '100',
                                        },
                                        {
                                          'name'  : '>100',
                                          'id' : '101',
                                        }
                                      ];
   public static lead_Status    =  [
                                        {
                                          'name'  : 'Active',
                                          'id' : '1',
                                        },
                                        {
                                          'name'  : 'In Active',
                                          'id' : '0',
                                        }
                                       ];
   public static leadInterest       =   ['Shop', 'Luxury apartment', 'Food court Shop', 'Not sure yet'];
   // public static leadStatus         = ['New Lead', 'In Contact', 'Call back', 'Not interested', 'Wrong No', 'Appointment', 'Rescheduled', 'No response', 'Uncertain', 'Duplicate', 'Visited', 'Sale','Did not qualify','Invalid'];
   public static leadStatus         =   ['New Lead', 'In Contact', 'No response', 'Call back', 'Wrong No', 'Uncertain', 'Hang Up', 'NI-Already invested somewhere else', 'NI-Non-responsive', 'NI-First call', 'NI-After Follow ups', 'Duplicate', 'Appointment', 'Follow Up', 'Rescheduled', 'Visited', 'Sale', 'Did not qualify' ];
   public static AppointmentStatus  =   ['Appointment', 'Rescheduled', 'No response', 'Call back', 'Visited', 'Sale', 'Not interested'];
   public static visitStatus        =   ['Visited', 'Sale', 'PFSOE', 'Follow Up', 'Awaiting decision', 'Not interested', 'Uncertain', 'Customer will contact'];
   public static realtorsStatus     =   ['New Realtor', 'Briefing given', 'Signup Kit sent'];
   public static BinStatus          =   ['New Lead', 'Call back', 'Not interested', 'Wrong No', 'Appointment', 'Rescheduled', 'Visited', 'Follow Up', 'No response', 'Uncertain', 'Duplicate', 'Sale'];
   public static projectClass          : any = {
                                         0: 'gharbaar-project',
                                         1: 'epark-project',
                                         2: 'sixthRoad',
                                         3: 'epark-project',
                                         4: 'tlr',
                                      };
public static slotHoursArr24       = [
                                               {val: '10' ,name:'10'},
                                               {val: '11' ,name:'11'},
                                               {val: '12' ,name:'12'},
                                               {val: '13' ,name:'01'},
                                               {val: '14' ,name:'02'},
                                               {val: '15' ,name:'03'},
                                               {val: '16' ,name:'04'},
                                               {val: '17' ,name:'05'},
                                               {val: '18' ,name:'06'},
                                               {val: '19' ,name:'07'},
                                               {val: '20' ,name:'08'},

                                               {val: '21' ,name:'09'},
                                               {val: '22' ,name:'10'},
                                               {val: '23' ,name:'11'},
                                               {val: '00' ,name:'12'},
                                               {val: '01' ,name:'01'},
                                               {val: '02' ,name:'02'},
                                               {val: '03' ,name:'03'},
                                               {val: '04' ,name:'04'},
                                               {val: '05' ,name:'05'},
                                               {val: '06' ,name:'06'},
                                               {val: '07' ,name:'07'},
                                               {val: '08' ,name:'08'},
                                               {val: '09' ,name:'09'},
                                             ];

public static slotHoursArrDefault  = [
                                               {val: '10' ,name:'10'},
                                               {val: '11' ,name:'11'},
                                               {val: '12' ,name:'12'},
                                               {val: '13' ,name:'01'},
                                               {val: '14' ,name:'02'},
                                               {val: '15' ,name:'03'},
                                               {val: '16' ,name:'04'},
                                               {val: '17' ,name:'05'},
                                               {val: '18' ,name:'06'},
                                               {val: '19' ,name:'07'},
                                               {val: '20' ,name:'08'},
                                             ];

public static maxPriceLabel = { name: 'Any', value: 100000000000 };

public static searchPrices    = [ 500000, 1000000, 2000000, 3500000, 5000000, 6500000, 8000000, 10000000, 12500000, 15000000,
  17500000, 20000000, 25000000, 30000000, 40000000, 50000000, 75000000, 100000000,
  250000000, 500000000, 1000000000, 5000000000
];
public static pricesNew   = [ 'Under 50 Lac', 'Under 75 Lac', 'Under 1 Crore', 'Under 1.5 Crore',  'Under 2 Crore',
                            'Under 2.5 Crore', 'Under 3 Crore', 'Under 3.5 Crore', 'Under 4 Crore', 'Under 4.5 Crore', 'Under 5 Crore',];
public static searchAreas  = {
  ftsq  : [ 450, 675, 1125, 1800, 2250, 3375, 4500, 6750, 9000, 11250 ],
  yard  : [ 50, 60, 70, 80, 100, 120, 150, 200, 250, 300, 350, 400, 450, 500, 1000, 2000, 4000 ],
  meter : [ 50, 80, 130, 200, 250, 380, 510, 760, 1000, 1300, 1900, 2500, 3800, 5100, 6300, 13000, 19000, 25000, 51000 ],
  marla : [ 2, 3, 5, 8, 10, 15, 20, 30, 40, 50 ],
  kanal : [ 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 15, 20, 30, 50, 100 ]
};


public static sponsorshipTypeOptions = [
  { label: 'PSH', value: 'psh' },
  { label: 'Cadet', value: 'cadet' },
  { label: 'Day Scholar', value: 'day-scholar' },
  { label: 'University', value: 'university' },
];


public static cities  = [
    {"id": 11, "name" : "Islamabad"},
    {"id": 5,  "name" : "Rawalpindi"}
  ];

  public static filters            = [
                                {name:"Lead ID"             , value:"1"},
                                {name:"Name"                , value:"2"},
                                {name:"Email"               , value:"3"},
                                {name:"Phone"               , value:"4"},
                                {name:"Agent Name"          , value:"5"},
                                {name:"Source"              , value:"6"},
                                {name:"Status"              , value:"7"},
                              ];
                              public static completeFilterOpions = [
                                { label: 'Incomplete', value: 0 },
                                { label: 'Complete', value: 1 },
                              ];
  static typeOptions: any;

}
