import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AgentDetailService {
  private agentDetails  : any;

  constructor() {
    if (typeof localStorage != 'undefined') {
      this.agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
      if(this.agentDetails == null)
        this.agentDetails = {};
    }
  }

  setAgentDetails(id, name){
    this.agentDetails.agentId = id;
    this.agentDetails.agentName = name;
    localStorage.setItem('agentDetails', JSON.stringify(this.agentDetails));
  }

  getAgentDetails(){
    return this.agentDetails;
  }
}
