import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeDifferenceService {

  constructor() { }

  getTimeDifference(subtractFrom = null, startTime : any, limit : any = -1, endTime : any = ''){
    if(!startTime)
      return '';
    endTime      = endTime ? endTime.replace(/\s/g, "T") : new Date();
    startTime    = startTime.replace(/\s/g, "T");
    startTime    = Date.parse(startTime);
    endTime      = Date.parse(endTime);
    // get total seconds between the times
    var delta   = (endTime - startTime)/ 1000; // milliseconds startTime now & endTime
    var totalMinutes = delta/60;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    // what's left is seconds
    var seconds = delta % 60;  // in theory the modulus is not required
    if(totalMinutes > limit)
      return `${days} DD ${hours} Hrs ${minutes} Mins ${seconds} sec`;
    else
      return '';
  }
}
