import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { AppSettings } from './app.setting';
import { BehaviorSubject, Observable, throwError, Subject } from 'rxjs';
import { catchError, retry, finalize, tap, map, takeUntil } from 'rxjs/operators';

declare var config: any;

import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class HelperService {
    public corsHeaders: any = new HttpHeaders({
    });;

    public corsHeaders2: any = new HttpHeaders({
      'Content-Type': '',
      'Accept': 'application/json',
    });

    public searchParms : any;

    // Https Params
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    public httpLoading     = false;

    public keySub: any;

    constructor(
      private http: HttpClient,
      private authenticationService: AuthenticationService,
    ) {
        if(typeof localStorage != "undefined") {
          // // Set Params OnLoad
          // this.searchParms = JSON.parse(localStorage.getItem('searchParms'));

          this.keySub = this.authenticationService.UserLoginKeyS$.subscribe(loginKey => {
            this.corsHeaders = {};
            this.corsHeaders = {
                        headers: new HttpHeaders()
                          .set('Cache-Control',  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0')
                          .set('Pragma',   'no-cache')
                          .set('Expires',  '0')
                          .set('Authorization',  `Bearer ${loginKey}`)
                          // .set('AuthKey',  ``+ loginKey)
                      }

                      this.corsHeaders2 = {
                        headers: new HttpHeaders()
                          .set('Accept',  'application/json')
                          .set('Authorization',  `Bearer ${loginKey}`)
                      }

          });
        }

        // let subscription = this.authenticationService.currentUserS$.subscribe(user => {
        //   if (user) {
        //       this.corsHeaders = {
        //             headers: new HttpHeaders()
        //               .set('Content-Type',  'application/json')
        //               .set('Accept',  'application/json')
        //               // .set('Authorization',  `Bearer ${user.access_token}`)
        //           }
        //   } else {
        //       this.corsHeaders = {};
        //       this.corsHeaders = new HttpHeaders({
        //                                 'Content-Type': 'application/json',
        //                                 'Accept': 'application/json',
        //                               });;
        //   }
        // });
    }

    ngOnDestroy(): void {
      if(this.keySub) {
        this.keySub.unsubscribe();
      }
    }

    //////////////////////////////////////////
    /********* HTTP Requests Fns ***********/
    ////////////////////////////////////////
    httpGetRequests(url): Promise<any> {
        // Set loader true
        this.httpLoading = true;

        return this.http.get(url, this.corsHeaders)
            .pipe( takeUntil(this.ngUnsubscribe) )
            .toPromise()
            .then( resp => {
                // Set loader false
                this.httpLoading = false;

                return resp;
                // console.log("resp: ",resp);
            })
            .catch(error => {
                // Set loader false
                this.httpLoading = false;
                console.log("helperFunc error: ",error);

                // Show Error Msg
                if(typeof error.error != "undefined") {
                    if(error.error.status == "403" || error.error.status == "401") {
                      this.authenticationService.simpleLogout();
                    }

                    throw error;
                } else {
                    throw "Something went wrong. Please try again.";
                }
            });
      }

      httpPostRequests(url, data): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.post(url, data, this.corsHeaders)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;
                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;
                  console.log("error: ",error);
                  if(error?.status == 401)
                  this.authenticationService.simpleLogout();
                    
                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                    if(error.error.status == "403" || error.error.status == "401") {
                      this.authenticationService.simpleLogout();
                    }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }

        httpPostRequestsMulti(url, data): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.post(url, data, this.corsHeaders2)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;
                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;
                  console.log("error: ",error);

                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                      if(error.error.message == "Unauthenticated.") {
                        this.authenticationService.logout();
                      }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }

      httpDeleteRequests(url): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.delete(url, this.corsHeaders)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;

                  console.log("resp: ",resp);
                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;

                  console.log("error: ",error);

                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                      if(error.error.status == "403" || error.error.status == "401") {
                        this.authenticationService.simpleLogout();
                      }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }


  //////////////////////////////////////////
  /********* new  detail modal changes *********/
  ////////////////////////////////////////
   getParentLocations(id): Promise<any> {
      const url = AppSettings.API_ENDPOINT_AREA + "locations/get-parent-locations-by-sublocation?sub_loc=" + id;
      return this.httpGetRequests(url).then(resp => {

        if (typeof resp.p_loc != "undefined") {
            let searchedArea = resp.p_loc.slice()[0];

            let parentLocs  = [...resp.p_loc.slice().reverse()];

            // Remove the Last parent as its the same as searched area
            parentLocs.pop();

            return {searchedArea: searchedArea, parentLocs: parentLocs};
        }
      }).catch(error => {
          console.log("error: ",error);
      })
    }

    caonvertDateToString(timestamp){
      var x = new Date(timestamp);
      var dd = x.getDate() > 9 ? x.getDate() : '0' + x.getDate();
      var mm = x.getMonth() + 1 > 9 ? x.getMonth() + 1 : '0' + (x.getMonth() + 1);
      var yy = x.getFullYear();
      return yy +"-" + mm+"-" + dd;
    }
}
