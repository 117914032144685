import { Component, OnInit } from '@angular/core';
import { Router, Route, ActivatedRoute } from "@angular/router";
import { AppSettings, AuthenticationService, UserService, HelperService, ProjectService, ChildProjectsService } from '../../../services/_services';
import Swal from 'sweetalert2';

declare var google, window;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public admin          : any = { email: '', password: '', isAdmin: false };
  public UserLoginKey   : any;
  public projectDetails : any;
  public childProjects  : any;

  public loading        : any = false;
  public showErrorAlert : any = false;
  public ErrorMessage   : any = '';

  public animationDone   : any = false;
  public authBrowserSub  : any;
  public projectSub      : any;
  public navDateTime     : any;
  public hours           : any;
  public myInterval      : any;

  constructor(
    private userService           : UserService,
    private authenticationService : AuthenticationService,
    private activatedRoute        : ActivatedRoute,
    private router                : Router,
    private helperService         : HelperService,
    private projectService        : ProjectService,
    private childProjectsService  : ChildProjectsService,
  ) {
      this.authBrowserSub = this.authenticationService.isBrowser.subscribe(isBrowser => {
        if (isBrowser) {
            this.projectSub = this.projectService.projectDetailsS$.subscribe(projectDetails => {
              this.projectDetails = projectDetails;
              // if(this.projectDetails && this.projectDetails.id && AppSettings.projectClass[this.projectDetails.id])
              //   $('body').addClass(AppSettings.projectClass[this.projectDetails.id]);
              // else
              //   $('body').addClass(AppSettings.projectClass[0]);
            });

            if (this.authenticationService.get_currentUserValue()) { // Redirect to dashboard if already logged in
                this.router.navigate(['/leads']);
            } else {
                // Get User login key
                this.UserLoginKey = this.authenticationService.get_UserLoginKey();
            }
        } else {
            // console.log("********* Inside Server State ");
        }
      });
  }

  ngOnInit() {
    this.navDateTime = new Date();
    this.hours = this.navDateTime.getHours();
  }

  ngAfterViewInit() {
    let self = this;
    setTimeout(function() {
      self.animationDone = true;
    }, 1000);
    if(window.innerWidth < 768){
      setTimeout(() => {
        Swal.fire('', 'For better experience please use the desktop version', 'warning');

      }, 500);
    }
  }

  ngOnDestroy(): void {
    if(this.authBrowserSub) {
      this.authBrowserSub.unsubscribe();
    }

    if(this.projectSub) {
      this.projectSub.unsubscribe();
    }
  }

  convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}

  closeAlert() {
    this.showErrorAlert = false;
  }

  login() {
    // Close previous errors
    this.closeAlert();

    // Show Loader
    this.loading = true;

        // let data = new FormData(this.admin);
        let url  = AppSettings.API_ENDPOINT + 'login';
        let data = new FormData();
        // data.append("action",     "login");
        data.append("email",      this.admin.email);
        data.append("password",   this.admin.password);

        data.append("login_key", this.UserLoginKey);

        // if(this.UserLoginKey)
        //   data.append("login_key", this.UserLoginKey);
        // else {
        //   this.UserLoginKey = this.randomString(32, '#aA');
        //   data.append("login_key", this.UserLoginKey);
        // }

        this.helperService.httpPostRequests(url, data)
           .then(user => {
                // Hide Loader
                this.loading = false;
                console.log("user",user);
                if(!user.error) {
                    this.authenticationService.setCurrentUserValue(user.admin);
                    this.authenticationService.setUserLoginKey(user.token);


                    localStorage.setItem('UserRole', user.admin.roles[0]['name']);
                    if(user.admin.roles[0]['name'] == 'accountant' ){
                      this.router.navigate(['/new-journal']);
                    }else if(user.admin.roles[0]['name'] == 'user_admin' ){
                      this.router.navigate(['/users']);
                    } else {
                      this.router.navigate(['/leads']);
                    }

                    // this.projectService.setProject(user['project'][0]);
                    // this.getChildProjects()
                    // .then(resp =>{
                    //   this.router.navigate(['/leads']);
                    // });
                } else {
                    // Show Error Measage
                    this.showErrorAlert = true;
                    this.ErrorMessage   = user.error;
                }
            })
            .catch((error)=>{
              this.loading = false;
              if(error?.error?.message)
                Swal.fire('Error', error?.error?.message, 'error');
              console.log("error: ",error);
            });
  }

  getIpAddress(): Promise<any> {
    let url  = AppSettings.API_ENDPOINT + 'admin.php';
    let data = new FormData();
    data.append("action", "get_client_ip");
    return this.helperService.httpPostRequests(url, data).then(resp => {
        return resp;
    }).catch(error => {
        console.log("error: ", error);
    });
  }

  // getAdress(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(resp => {
  //         let pos = {lng: resp.coords.longitude, lat: resp.coords.latitude};
  //         var geocoder = new google.maps.Geocoder;
  //         geocoder.geocode({'location': pos}, function(results, status) {
  //           if (status === 'OK') {
  //             if (results[0]) {
  //                 resolve(results[0].formatted_address);
  //             }
  //           }
  //         });
  //       },
  //       err => {
  //         reject(err);
  //       });
  //   });
  // }

  randomString(length, chars) {
    var mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    var result = '';
    for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
    return result;
  }

  geocodeLatLng(pos) {
    var geocoder = new google.maps.Geocoder;
    geocoder.geocode({'location': pos}, function(results, status) {
      if (status === 'OK') {
        if (results[0]) {
            console.log("results[0]: ",results[0]);
        }
      }
    });
  }

  getBrowserInfo() {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion,10);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
     browserName = "Opera";
     fullVersion = nAgt.substring(verOffset+6);
     if ((verOffset=nAgt.indexOf("Version"))!=-1)
       fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
     browserName = "Microsoft Internet Explorer";
     fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
     browserName = "Chrome";
     fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
     browserName = "Safari";
     fullVersion = nAgt.substring(verOffset+7);
     if ((verOffset=nAgt.indexOf("Version"))!=-1)
       fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
     browserName = "Firefox";
     fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) <
              (verOffset=nAgt.lastIndexOf('/')) )
    {
     browserName = nAgt.substring(nameOffset,verOffset);
     fullVersion = nAgt.substring(verOffset+1);
     if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
     }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!=-1)
       fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!=-1)
       fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
     fullVersion  = ''+parseFloat(navigator.appVersion);
     majorVersion = parseInt(navigator.appVersion,10);
    }

    // console.log("browserName: ",browserName);
    // console.log("fullVersion: ",fullVersion);
    // console.log("majorVersion: ",majorVersion);
    // console.log("navigator.appName: ",navigator.appName);
    // console.log("navigator.userAgent: ",navigator.userAgent);
    return browserName;
  }

  getChildProjects(): Promise<any>
  {
    // Show Loader
    this.loading = true;

    // let data = new FormData(this.admin);
    let url  = AppSettings.API_ENDPOINT + 'adminPortal_users.php';
    let data = new FormData();
    data.append("action",     "getChildProjects");

    return this.helperService.httpPostRequests(url, data)
     .then(resp => {
          if(resp) {
              this.childProjectsService.setChildProjects(resp);
          } else {
              // Show Error (Project Not Found)
              Swal.fire('', 'Child Project Not Found', 'error');
          }
          // Hide Loader
          this.loading = false;

      },
      error => {
          console.log("error: ",error);

          // Hide Loader
          this.loading = false;
      });
  }

}
