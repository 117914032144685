import { Injectable, Inject, PLATFORM_ID  } from '@angular/core';
import { AppSettings } from './app.setting';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectService  {

    public projectDetails : any;

    private projectDetailsS: BehaviorSubject<any> = new BehaviorSubject<any>(this.projectDetails);
    projectDetailsS$: Observable<any> = this.projectDetailsS.asObservable();

    constructor() {
      if(typeof localStorage != "undefined") {
        if(localStorage.getItem('projectDetails')) {
          this.projectDetails  = JSON.parse(localStorage.getItem('projectDetails'));
          this.projectDetailsS.next(this.projectDetails);
        }
        else
          this.projectDetails = [];
      }
    }

    setProject(projectDetails) {
      try {
        localStorage.setItem('projectDetails', JSON.stringify(projectDetails));
        this.projectDetails = projectDetails;
        this.projectDetailsS.next(this.projectDetails);
      }
      catch (e) {

      }
    }

    removeProject() {
      try {
        localStorage.removeItem('projectDetails');
        this.projectDetails = null;
        this.projectDetailsS.next(this.projectDetails);
      }
      catch (e) {

      }
    }

    matchProject_url_key(url_key) {
      if(url_key == this.projectDetails.url_key)
        return this.projectDetails;
      else
        return false;
    }

    getProject() {
      return this.projectDetails;
    }
}
