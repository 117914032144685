import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './pages/component/login/login.component';
import { AuthGuard } from './services/_helpers';

const routes: Routes = [
  // {
  //   path: '',
  //   component: PageComponent,
  //   canActivate: [ AuthGuard ],
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  //       // loadChildren: './pages/pages.module#PagesModule'
  //     }
  //   ]
  // },

  // {
  //   path: '',
  //   component: LoginComponent,
  // },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/:project',
    component: LoginComponent,
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      // preloadingStrategy: NoPreloading,
      // initialNavigation: 'enabled'
    }),
    NgbModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
